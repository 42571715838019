<template>
  <div class="navbar">
    <!-- Brand and navigation links container -->
    <div class="left-group">
      <!-- Logo or site name with icon -->
      <a href="/" class="brand-container">
        <img src="/AI.png" alt="Logo" class="navbar-logo">
        <h1 class="navbar-brand">DataAnalysis Corner</h1>
      </a>
      <!-- Navigation Links -->
      <nav>
        <ul class="nav-links">
          <li><a href="/">首页</a></li>
          <li><a href="/principle">统计原理</a></li>
          <li><a href="/software">软件操作</a></li>
        </ul>
      </nav>
    </div>
    <!-- Search and auth links container -->
    <div class="right-group">
      <!-- Search Box -->
      <div class="search-container">
        <input type="text" v-model="SearchText" placeholder="搜索..." class="search-input" @keyup.enter="GetSearchtext">
        <button class="search-button" @click="GetSearchtext">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" >
            <path fill="#000000" d="m19.485 20.154l-6.262-6.262q-.75.639-1.725.989q-.975.35-1.96.35q-2.402 0-4.066-1.663q-1.664-1.664-1.664-4.065T5.47 5.436q1.663-1.667 4.064-1.667q2.402 0 4.068 1.664q1.666 1.664 1.666 4.067q0 1.042-.369 2.017q-.37.975-.97 1.668l6.262 6.261zM9.538 14.23q1.99 0 3.361-1.37q1.37-1.37 1.37-3.361q0-1.99-1.37-3.36q-1.37-1.37-3.36-1.37q-1.99 0-3.361 1.37q-1.37 1.37-1.37 3.36q0 1.99 1.37 3.36q1.37 1.37 3.36 1.37"></path>
          </svg>
        </button>
      </div>

      <!--user-section -->
      <div class="user-section">
        <div v-if="isLoggedIn">
  <!-- 显示用户图标和昵称 -->
        <button class="status-btn" @click="showStatusModal = true">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFFFFF" class="bi bi-person" viewBox="0 0 16 16">
        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
        </svg>
        <p class="name">{{ nickname }}</p>
        </button>
    <div class="logout" v-if="shouldShowLogout" >
      <button @click="logout">Logout</button>
    </div>
        </div>
      <div v-else>
        <!-- 未登录时显示的登录和注册链接 -->
        <a href="/login" class="login-btn">登录</a>
        <a href="/register" class="register-btn">注册</a>
      </div>
        
      </div>
    </div>
  </div>

  <!-- Status Modal -->
 
</template>


<script>
export default {
  name: 'WebNavbar',
  data() {
    return {
      showStatusModal: false, // 控制状态模态框是否显示的布尔值
      SearchText: "",
    };
  },
  // 组件逻辑
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    nickname() {
      return this.$store.state.nickname;
    },
     // 计算属性用于决定是否显示注销部分
     shouldShowLogout() {
      return this.isLoggedIn && this.showStatusModal;
    },
  },
  methods:{
    logout() {
      console.log(1)
            // 在这里执行退出登录的逻辑，例如清除 token、重定向到首页等
            localStorage.removeItem('token');
            this.showStatusModal = false;
            this.userID = " "
            this.$store.commit('setUserID', this.userID);
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('nickname');
          // 更新 Vuex 状态
     this.$store.commit('setLoginState', { isLoggedIn: false, nickname: '' });
        },
        GetSearchtext() {
          localStorage.removeItem('SearchText');
          this.$store.commit('setSearchText', this.SearchText);
          localStorage.setItem('SearchText', this.SearchText); // 存储搜索文本
          // 完成后进行跳转
          window.location.href = '/search';
        }
        }
};




</script>

<style>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  padding: 0.5rem 1rem;
}

.left-group,
.right-group {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust this value as needed */
}

.brand-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px; /* Adjust as needed */
  margin-right: 10px;
}

.navbar-brand {
  font-size: 1.5rem;
  color: #333;
  margin: 0; /* Remove default margin */
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center; /* 这将垂直居中所有子项 */
  padding: 0; /* 移除默认的padding */
  margin: 0; /* 移除默认的margin */
}

.nav-links li {
  display: flex; /* 应用flex布局以便使用align-items */
  align-items: center; /* 这将垂直居中 <a> 标签 */
  margin-right: 20px; /* 或者使用gap属性来设置链接之间的距离 */
}

.nav-links li a {
  font-size: 1.2rem; /* 调大字体大小 */
  font-weight: bold; /* 加粗字体 */
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
}

.search-button, .login-btn, .register-btn, .search-input {
  height: 40px; /* 统一高度 */
  font-size: 1rem; /* 统一字体大小 */
}

.search-container {
  height: 45px; /* 减少高度 */
  margin-right: 10px; /* 添加右边距 */
  display: flex;
  position: relative; /* 新增相对定位 */
  border: 1px solid #ddd;
  border-radius: 5px; /* 如需要圆角，则保持这个属性 */
  
}

.search-input {
  font-size: 1rem;
  height: 45px; /* 减少高度 */
  margin-right: 40px;
  padding: 0 12px; /* 根据需要调整内边距 */
  border-radius: 5px; /* 如需要圆角，则保持这个属性 */
  border: none;
}

.search-button {
  position: absolute; /* 绝对定位 */
  right: 0; /* 往右边偏移一点 */
  
  border: none;
  background-color: transparent;
  cursor: pointer; /* 新增手形光标，表示可点击 */
  /* 调整图标的大小和位置 */
  display: flex;
  padding: 0.5rem; /* 统一内边距，可能需要根据实际文本内容进行调整 */
  

}
.user-section {
  display: flex;
  gap: 10px; /* Adjust this value to bring buttons closer to the search box */
  margin-right: 40px;
}

.login-btn,
.register-btn {
  background-color: #555;
  padding: 0.5rem 1rem; /* 统一内边距，可能需要根据实际文本内容进行调整 */
  border-radius: 5px; /* 边框圆滑效果，根据需要调整 */
  border: none;
  color: white;
  cursor: pointer;
  margin: 5px
}

.status-btn {
  display: flex; /* 使用Flexbox布局 */
  
  align-items: center; /* 垂直居中所有子项 */
  gap: 5px; /* 在子项之间设置间距 */
  background: none; /* 如果需要的话，移除按钮默认的背景 */
  border: none; /* 如果需要的话，移除按钮默认的边框 */
  color: black; /* 设置字体颜色 */
  cursor: pointer; /* 显示指针光标来表明这是一个按钮 */
  height: 40px; /* 统一高度 */
}

.name {
  display: flex;
  align-items: center; /* 确保文本在Flex容器中垂直居中 */
  margin: 0; /* 移除默认的外边距 */
  padding: 0; /* 如果需要，可以根据实际效果调整内边距 */
  line-height: 1; /* 行高与图标的高度一致 */
  height: 100%; /* 如果.status-btn的高度被设置，这将填满按钮的高度 */
}

/*调整SVG图标的样式 */
.bi-person {
  fill: currentColor; /* 让SVG图标使用继承的文字颜色 */
}



</style>

  