
import request from './StrapiHttp'

export function getArticlesAPI() {
  return request({
    url: '/api/articles',
    params: {
      populate: '*'
    }
  });
}

export function getTagsById(tagsId) {
  return request({
    url: `/api/tags/${tagsId}`,
    params: {
      populate: '*'
    }
  });
}


export function getArticleById(articleId) {
  return request({
    url: `/api/articles/${articleId}`,
    params: {
      populate: '*', // 假设您需要填充所有相关的字段
    }
  });
}