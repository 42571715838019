import { createRouter, createWebHistory } from 'vue-router';



const routes = [
  // ...您项目中的其他路由
  {
    path: '/articles/:articleId',
    name: 'ArticleDetail',
    // 请确保 'ArticleDetailComponent.vue' 文件在您项目的 '@/components/' 目录下
    component: () => import('../components/ArticleDetailComponent.vue')
  },
  {
    path: '/principle',
    name: 'PrincipleArticles',
    component:  () => import('../components/PrinciplePage.vue')
  },
  {
    path: '/software',
    name: 'SoftwareArticles',
    component:  () => import('../components/SoftwarePage.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component:  () => import('../components/LoginPage.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component:  () => import('../components/RegisterPage.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component:  () => import('../components/SearchPage.vue')
  },
];

// 创建路由实例
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  
  export default router;
