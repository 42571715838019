

// 从'vue'库中导入'createApp'函数。这是Vue 3中新的应用创建方法。
import { createApp } from 'vue'


// 从当前目录下的'App.vue'文件中导入名为'App'的默认组件，'App.vue'通常是应用的主组件
import App from './App.vue'
import store from './store'
import router from './router'



// 使用'createApp'函数创建一个新的Vue应用实例，并传入'App'组件作为该应用的根组件。
const app = createApp(App)

store.dispatch('checkLoginState');

app.use(store);
app.use(router);



// 使用'mount'方法挂载这个Vue应用实例到ID为'app'的DOM元素上。
app.mount('#app')


