<template>
  <WebNavbar />
  <WebArticle v-if="showArticleList" />
  <router-view></router-view>
</template>

<script>
//import AppSidebar from './components/AppSidebar.vue'
//import InputBox from './components/InputBox.vue'
//import SuggestionBar from './components/SuggestionBar.vue'
//import WebName from './components/WebName.vue'
import WebNavbar from './components/WebNavbar.vue'
import WebArticle from './components/WebArticle.vue'

export default {
  name: 'App',
  components: {
    
    WebNavbar,
    WebArticle
  },
  computed: {
    // 当路由不是'article-detail'时显示WebArticle2
    showArticleList() {
      // 根据项目的需要，可能还需要检查其他路由
      return this.$route.path === '/';
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}


 /* 如果你想要确保整个页面都是这种颜色，你可以加上以下代码： */
body {
  background-color: #ebecf0; 
  margin: 0;
  min-height: 100vh;
}



</style>
