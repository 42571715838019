<template>
  <div class="container">
    <div class="articles-container">
      <!-- 使用普通的 a 标签和 target="_blank" 来在新标签页打开链接 -->
      <div
        v-for="article in articles"
        :key="article.id"
      >
        <a
          :href="`/articles/${article.id}`"
          target="_blank" 
        >
          <article class="article-card">
            <h2 class="article-title">{{ article.title }}</h2>
            <p class="article-summary">{{ article.summary }}</p>
            <div class="article-meta">
              <span class="article-author">{{ article.author }}</span>
              <span class="article-date">{{ formatDate(article.publishedAt) }}</span>
            </div>
          </article>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

//导入基地址(拼接图片地址用)

// 导入接口

import{ getArticlesAPI } from '../utils/StrapiApi'


export default {
  data() {
    return {
      articles: [],
      error: null
    };
  },
  created() {
    this.fetchArticles();
  },
  methods: {
    fetchArticles() {
    getArticlesAPI()
      .then(response => {
        // 假设 response.data 是一个包含文章对象的数组
        this.articles = response.data.map(article => {
          // 访问每个文章对象的 attributes 属性
          const attrs = article.attributes;
          return {
            // 直接访问或通过某种方式转换数据
            id: article.id,
            title: attrs.Title,
            summary: attrs.Abstract,
            author: attrs.Author,
            publishedAt: attrs.publishedAt, // 或 Date
          };
        });
        console.log(this.articles);
      })
      .catch(error => {
        this.error = error.toString();
      });
  },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString('default', {
          year: 'numeric', month: 'short', day: 'numeric'
        });
      }
      return '';
    }
  }
};

</script>



<style>

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 继续使子元素靠左对齐 */
  margin: 50px;
  margin-top:70px;
  padding-left: 0.1%; /* 减少左侧内边距，或者你可以根据需要调整这个百分比 */
  padding-right: 40%; /* 右侧内边距保持不变 */
  padding-top: 20px; /* 导航栏下方的空间 */
}

.articles-container {
  width: 100%;
 

}

a {
  text-decoration: none; /* 移除下划线 */
}

.article-card {
  background-color: #fff; /* 这应该与导航栏的背景色一致 */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* 卡片之间的空间 */
  padding: 16px; /* 卡片内部的空间 */
  width: 100%;
}

.article-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  text-align: left; /* 从左边开始 */
}

.article-summary {
  font-size: 1rem;
  color: #333;
  margin-top: 0.5rem;
  text-align: left; /* 从左边开始 */
}

.article-meta {
  display: flex;
  justify-content: ; 
  align-items: center;
  font-size: 0.9rem;
  color: #666;
}

.article-author,
.article-date {
  margin-right: 1rem; /* 添加右边距以分隔作者和日期 */
}


.article-date {
  font-size: 0.9rem;
  color: #666;
  text-align: left;
}
</style>
