
// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    userID: null,           // 添加用于存储当前用户ID的状态
    currentSessionId: null,
    userMessages: [],  // 用户消息数组
    apiResponses: [],   // API响应数组
    historyuserMessages: [],
    historyapiResponses: [],
    isEditing: false,
    isLoggedIn: false,
    nickname: '',
    SearchText: '' // 初始化搜索文本状态

  },
  mutations: {
    setUserID(state, userID) {
      state.userID = userID;
    },
    setCurrentSessionId(state, currentSessionId) {
      state.currentSessionId = currentSessionId;
    },
    addUserMessage(state, message) {
      state.userMessages.push(message);
    },
    addApiResponse(state, response) {
      state.apiResponses.push(response);
    },
    getHistoryUserMessage(state, message) {
      state.historyuserMessages.push(message);
    },
    getHistoryApiResponse(state, response) {
      state.historyapiResponses.push(response);
    },
    CLEAR_HISTORY(state) {
      state.historyuserMessages = [];
      state.historyapiResponses = [];
    },
    setisEditing(state,newValue) {
      state.isEditing = newValue;
    },
    addLoading(state, loadingMessage) {
      state.userMessages.push(loadingMessage);
    },
    // 移除一个特定ID的加载消息
    removeLoading(state, loadingMessageId) {
      state.userMessages = state.userMessages.filter(message => message.id !== loadingMessageId);
    },
    setLoginState(state, { isLoggedIn, nickname }) {
      state.isLoggedIn = isLoggedIn;
      state.nickname = nickname;
    },
    setSearchText(state, text) {
      state.SearchText = text;
    },
  },
  actions: {
    // 加载历史记录到Vuex
    loadHistory({ commit }, historySessionMessages) {
      commit('CLEAR_HISTORY'); // 清空当前的历史记录，如果需要的话
      const sortedHistory = historySessionMessages.sort((a, b) => a.message_id - b.message_id);
        sortedHistory.forEach(message => {

          commit('getHistoryUserMessage', message.user_input);
          commit('getHistoryApiResponse', message.ai_response);
      })
    },
    // 检查存储并更新状态
    checkLoginState({ commit }) {
      const isLoggedIn = localStorage.getItem('isLoggedIn') ;
      const nickname = localStorage.getItem('nickname');
      commit('setLoginState', { isLoggedIn, nickname });
    },
    checkSearctState({ commit }) {
      const SearchText = localStorage.getItem('SearchText') ;
      commit('setSearchText', SearchText);
    }
  },
    

  getters: {
    // 添加一个getter来获取当前的userID
    getUserID(state) {
      return state.userID;
    },
    getCurrentSessionId(state) {
      return state.currentSessionId;
    },
    // ...可能的其他getters
  }

});
