// Step 1: Import axios
import axios from 'axios';

// Step 2: Define the base URL 
const baseURL = "/strapi";
// Step 3: Create an axios instance with the base URL and a timeout
const request = axios.create({
  baseURL,
  timeout: 5000
});

// Step 4: Add a response interceptor
request.interceptors.response.use(
  response => {
    // Data stripping - return only the data from the response
    return response.data;
  },
  error => {
    // Error handling - return the error to be handled in the catch block
    return Promise.reject(error);
  }
);

// Step 5: Export the axios instance
export default request;
